import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ItemBox from "./ItemBox";
import PigsMasterItemBox from "./PigsMasterItemBox";

export default function TodoViewCol(props) {
    const {
        items = [],
        isPigsMaster = false,
        onStatusChange = (id, status) => {

        }
    } = props;

    return (
        <Wrapper>

            {items.map((a, i) => {
                return (
                    <Item key={`${a.id}_${i}`}>
                        {isPigsMaster == true ? <PigsMasterItemBox {...a}
                            onStatusChange={(newStatus) => {
                                onStatusChange(a.id, newStatus);
                            }}
                        /> : <ItemBox {...a} isPigsMaster={isPigsMaster} />}
                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Item = styled.div`
  margin-bottom: 20px;
`;