import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components'

export const Input = styled.input`
    font-family: Montserrat;
    width: 100%;
    height: 44px;
    line-height: 42px;
    outline: none;
    border: 1px solid #8593A8;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 15px;
    color: #1F2041;
    font-size: 14px;
    font-weight: normal;
    :focus{
      border-color: #085BFF;
    }
    ::placeholder{
      color: rgba(31, 32, 65, 0.25);
    }
`;

export const Textarea = styled.textarea`
    font-family: Montserrat;
    width: 100%;
    line-height: 42px;
    outline: none;
    border: 1px solid #8593A8;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 15px;
    color: #1F2041;
    font-size: 14px;
    font-weight: normal;
    :focus{
      border-color: #085BFF;
    }
    ::placeholder{
      color: rgba(31, 32, 65, 0.25);
    }
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8593A8;
    margin-bottom: 5px;
`;
