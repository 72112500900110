import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// import App from './App';
import reportWebVitals from './reportWebVitals';

import * as usersActions from './redux/actions/UsersActions'

import ls from 'local-storage'

import * as axios from 'axios'
import {StoreContext} from "redux-react-hook";
import RouterApp from "./components/apps/RouterApp";
import {applyMiddleware, compose, createStore} from "redux";
import {reducer} from "./redux/reducers";
import {default as ReduxThunk} from "redux-thunk";
import logger from "redux-logger";

axios.defaults.timeout = 10 * 60 * 1000;
// axios.defaults.baseURL = 'https://api.entsu.ru-bdspb-1.englishpatient.org';

const store = createStore(
    reducer,
    undefined,
    compose(
        applyMiddleware(ReduxThunk), applyMiddleware(logger)
    )
)

const getToken = () => {
    let tk = ls('auth_token');
    if (tk == undefined || tk == '') {
        return;
    }
    return tk;
};

axios.interceptors.request.use((config) => {
    let token = getToken();
    if (token != undefined) {
        let url = new URL(config.url);
        // url.searchParams.set('token', token);
        config.url = url.toString();
        config.headers['Authorization'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={store}>
            <RouterApp/>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


let init = () => {
    return (dispatch, getState) => {
        return dispatch(usersActions.initializeAuthorization())
    }
}

// async function getConfig() {
//     console.log('getConfig occured!');
//     try {
//         let d = (await axios.get(`https://api.config.epatient.sabir.pro/config`, {
//             params: {
//                 host: window.location.href
//             }
//         })).data.result;
//         return d;
//     } catch (exc) {
//         return {
//             PATIENT_API_ENDPOINT: 'https://api.entsu.ru-spb-1.englishpatient.org' // some defaults
//         }
//     }
// }

// async function loadConfigForDomain() {
//     let d = await getConfig();
//     console.log('loadConfigForDomain: d = ', d);
//     for (let key in d) {
//         window[key] = d[key];
//     }
//     // axios.defaults.baseURL = 'https://api.entsu.ru-bdspb-1.englishpatient.org';
//     axios.defaults.baseURL = d.PATIENT_API_ENDPOINT;
//     if (d.WEBSITE_TITLE != undefined) {
//         document.title = d.WEBSITE_TITLE;
//     }
//     if (d.FAV_ICON_URL != undefined) {
//         try {
//             document.getElementById("favicon").href = d.FAV_ICON_URL;
//         } catch (exc) {
//
//         }
//     }
// }

// loadConfigForDomain().then(() => {

store.dispatch(init()).then(pld => {
    if (pld.user != undefined) {
        window.CURRENT_USER = pld.user;
        window.CURRENT_USER_ROLE = pld.user.userRole;
        console.log('global configs are set: CURRENT_USER = ', window.CURRENT_USER);
    }
});
// });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
