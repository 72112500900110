import React, {useState, useEffect, useRef} from 'react';

const ReactHelper = {

    useInterval(callback, delay) {
        const savedCallback = useRef();
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }

            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    },

    useEventListener(eventName, handler, element = window) {
        const savedHandler = useRef();
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;
                const eventListener = event => savedHandler.current(event);
                element.addEventListener(eventName, eventListener);
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element]
        );
    }

};

export default ReactHelper;
