import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import UserTemplate from "../templates/UserTemplate";
import ProxiesPanel from "../proxies/panels/ProxiesPanel";
import PigsTemplate from "../templates/PigsTemplate";
import TodoPanel from "../todos/dash/TodoPanel";
import CacheAPI from "../../api/CacheAPI";
import CommonHelper from "../../helpers/CommonHelper";

export default function VideoApp(props) {
    let {code} = props.match.params;
    let videoSRC = CacheAPI.getVideoSrcByCode(code);

    return (
        <PigsTemplate active={'video'}>



            <Wrapper onClick={() => {
                CommonHelper.linkTo('/rooms');
            }} >

                <HeaderPlaceholder onClick={() => {
                    CommonHelper.linkTo('/rooms');
                }} >
                    Станок 1
                </HeaderPlaceholder>

                <Video autoPlay={true} muted={true} loop={true} >
                    <source src={videoSRC}/>
                </Video>

            </Wrapper>

        </PigsTemplate>
    );
}

const HeaderPlaceholder = styled.h1`
    margin-bottom: 20px;
`;

const Video = styled.video`
  width: 100%;
  height: calc(100vh - 200px);
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  padding: 10px;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 10px;
`;