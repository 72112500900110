import * as types from '../ActionTypes.js'
import ProxiesAPI from '../../api/ProxiesAPI.js';

let createProxy_ = () => {
    return {
        type: types.CREATE_PROXY
    }
}
let createProxySuccess = (proxy) => {
    return {
        type: types.CREATE_PROXY_SUCCESS,
        proxy: proxy
    }
}
let createProxyFailed = (error) => {
    return {
        type: types.CREATE_PROXY_FAIL,
        error: error
    }
}

//thunk
export function createProxy(data) {
    return (dispatch, getState) => {
        dispatch(createProxy_());
        return ProxiesAPI.createProxy(data).then(
            proxy => dispatch(createProxySuccess(proxy)),
            error => dispatch(createProxyFailed(error))
        )
    }
}

//eca
let updateProxy_ = () => {
    return {
        type: types.UPDATE_PROXY
    }
}
let updateProxySuccess = (proxy) => {
    return {
        type: types.UPDATE_PROXY_SUCCESS,
        proxy: proxy
    }
}
let updateProxyFailed = (error) => {
    return {
        type: types.UPDATE_PROXY_FAIL,
        error: error
    }
}

//thunk
export function updateProxy(data) {
    return (dispatch, getState) => {
        dispatch(updateProxy_());
        return ProxiesAPI.updateProxy(data).then(
            proxy => dispatch(updateProxySuccess(proxy)),
            error => dispatch(updateProxyFailed(error))
        )
    }
}

let loadProxies_ = () => {
    return {
        type: types.LOAD_PROXIES
    }
}
let loadProxiesSuccess = (proxies) => {
    return {
        type: types.LOAD_PROXIES_SUCCESS,
        proxies: proxies
    }
}
let loadProxiesFailed = (error) => {
    return {
        type: types.LOAD_PROXIES_FAIL,
        error: error
    }
}

//thunk
export function loadProxies() {
    return (dispatch, getState) => {
        dispatch(loadProxies_());
        return ProxiesAPI.getAllProxies().then(
            proxies => dispatch(loadProxiesSuccess(proxies)),
            error => dispatch(loadProxiesFailed(error))
        )
    }
}

let deleteProxy_ = () => {
    return {
        type: types.DELETE_PROXY
    }
}
let deleteProxySuccess = (id) => {
    return {
        type: types.DELETE_PROXY_SUCCESS,
        id: id
    }
}
let deleteProxyFailed = (error) => {
    return {
        type: types.DELETE_PROXY_FAIL,
        error: error
    }
}

//thunk
export function deleteProxy(id) {
    return (dispatch, getState) => {
        dispatch(deleteProxy_());
        return ProxiesAPI.deleteProxy(id).then(
            () => dispatch(deleteProxySuccess(id)),
            error => dispatch(deleteProxyFailed(error))
        )
    }
}