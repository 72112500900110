import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import FACELESS_AVATAR from "../../assets/img/avatar.png";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";

import {Toaster} from 'react-hot-toast';

import emoji_cal from './images/icons/emoji_cal.svg';
import emoji_events from './images/icons/emoji_events.svg';
import rating_img from './images/icons/rating.svg';

import x_logo from '../../assets/img/x_logo.svg'
import profile_right from '../../assets/img/profile_right.svg'

export default function PigsTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading
    }), []));

    if (currentUser == undefined) {
        return null;
    }

    const {avatar, name, userRole} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    let links = LINKS;

    return (
        <Wrapper>

            <HeightPlaceholder>

                <TopPlaceholder>
                    <TopLeft>
                        <Logo src={x_logo}/>
                        <LinksPlaceholder>
                            {LINKS.map((x, i) => {
                                return (
                                    <LinkItem key={`x${i}${x.name}`} onClick={() => {
                                        CommonHelper.linkTo(x.link);
                                    }} >
                                        {x.label}
                                    </LinkItem>
                                )
                            })}
                        </LinksPlaceholder>

                    </TopLeft>

                    <TopRight>
                        <RightImg src={profile_right} onClick={async () => {
                            let pld = await dispatch(usersActions.logOut());
                            if (pld.error == undefined) {
                                CommonHelper.linkTo('/');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }
                        }} />
                    </TopRight>

                </TopPlaceholder>

            </HeightPlaceholder>

            <ContentPlaceholder>
                {props.children}
            </ContentPlaceholder>

            <Toaster/>

        </Wrapper>
    );
}

const RightImg = styled.img`
  height: 60px;
`;

const LinkItem = styled.div`
  margin-right: 30px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    opacity: 0.85;
  }
`;

const Logo = styled.img`
  height: 54px;
  margin-right: 32px;
`;

const TopPlaceholder = styled.div`
  width: 1200px;
  margin: 0 auto;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const TopRight = styled.div`

`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LinksPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentPlaceholder = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 10px;
`;

const HeightPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 70px;
  background: white;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #F9F9F9;
  font-family: Montserrat;
`;

const LINKS = [
    {
        name: 'index',
        link: '/',
        label: 'Панель управления',
        emojiIcon: emoji_cal,
        isTop: true
    },
    {
        name: 'containers',
        link: '/containers',
        label: 'Станки',
        emojiIcon: emoji_events,
        isTop: true
    },
    {
        name: 'settings',
        link: '/rooms',
        label: 'Видеотрансляция',
        emojiIcon: rating_img,
        isTop: true
    },
    {
        name: 'stats',
        link: '/stats',
        label: 'Статистика',
        emojiIcon: rating_img,
        isTop: true
    },
    {
        name: 'staff',
        link: '/staff',
        label: 'Сотрудники',
        emojiIcon: rating_img,
        isTop: true
    }

];
