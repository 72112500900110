import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import FACELESS_AVATAR from "../../assets/img/avatar.png";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";

import {Toaster} from 'react-hot-toast';

import emoji_cal from './images/icons/emoji_cal.svg';
import emoji_events from './images/icons/emoji_events.svg';
import rating_img from './images/icons/rating.svg';
import LogoDiv from "./LogoDiv";
import {translate} from "../../utils/Translate";
import LangSwitcher from "../../utils/LangSwitcher";
import LangSwitcherDropdown from "../../utils/LangSwitcherDropdown";

import ls from 'local-storage'

export default function UserTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading
    }), []));

    if (currentUser == undefined) {
        return null;
    }

    const {avatar, name, userRole} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    let links = LINKS;

    return (
        <Wrapper>
            {hasLeft == false ? null :
                <Left>
                    <LogoDiv/>
                    <LeftContent>
                        <CurrentUserPlaceholder onClick={() => {

                        }}>
                            <CurrentUserAvatarPlaceholder src={ava} onClick={() => {
                                CommonHelper.linkTo('/profile');
                            }}/>
                            <CurrentUserInfo>
                                <UserName onClick={() => {
                                    CommonHelper.linkTo('/profile');
                                }}>
                                    {CommonHelper.getUserName(currentUser)}
                                </UserName>
                                <UserRating>
                                    <span style={{cursor: 'pointer'}} onClick={() => {
                                        CommonHelper.linkTo('/profile');
                                    }}>
                                        {CommonHelper.getRuNameOfRole(userRole)}
                                    </span>
                                    <span style={{marginLeft: 10}}>
                                        <LangSwitcherDropdown/>
                                    </span>
                                </UserRating>
                            </CurrentUserInfo>
                        </CurrentUserPlaceholder>

                        <TopLinksPlaceholder>
                            {renderLinks(links.filter(a => (a.isTop == true)), active)}
                        </TopLinksPlaceholder>
                        <BottomLinksPlaceholder>
                            {renderLinks(links.filter(a => (a.isTop != true)), active)}
                        </BottomLinksPlaceholder>

                        <VeryBottomLinksPlaceholder>

                            <BottomLink onClick={async () => {
                                let pld = await dispatch(usersActions.logOut());
                                if (pld.error == undefined) {
                                    CommonHelper.linkTo('/');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 100);
                                }
                            }}>
                                {translate('logout')}
                            </BottomLink>
                        </VeryBottomLinksPlaceholder>

                    </LeftContent>
                </Left>
            }

            <Right id={'template_right_content'}>
                {props.children}
            </Right>

            <Toaster/>

        </Wrapper>
    );
}

const renderLinks = (links, active, onClick = () => {

}) => links.map((a, i) => {
    let isActive = (active == a.name);
    return (
        <LinkItem selected={isActive} key={`${a.name}_${i}`} onClick={() => {
            if (a.name == 'ideas') {

            } else {
                if (a.link == undefined) {
                    onClick();
                } else {
                    CommonHelper.linkTo(a.link);
                }
            }

        }}>
            {/*<LinkImage src={a.icon} selectedSrc={a.selectedIcon} selected={(isActive)}/>*/}
            <LinkImage src={a.emojiIcon} selectedSrc={a.emojiIcon} selected={(isActive)}/>
            <LinkLabel>
                {a.label}
            </LinkLabel>
        </LinkItem>
    )
});

const topHeight = 70;
const leftWidth = 300;

const TopLinksPlaceholder = styled.div`
  border-bottom: 1px solid rgba(133, 147, 168, 0.51);
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const BottomLinksPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const VeryBottomLinksPlaceholder = styled.div`
  box-sizing: border-box;
  padding-left: 30px;
  margin-bottom: 50px;
`;

const BottomLink = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};

  :hover {
    color: #FFA71B;
  }
`;


const LinkItem = styled.div`
  padding-left: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};
  border-left: 8px solid ${props => (props.selected ? '#FFA71B' : 'transparent')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    border-left: 8px solid #FFA71B;
    color: #FFA71B;
  }
`;

const LinkImage = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => (props.selected == true ? props.selectedSrc : props.src)});

  ${LinkItem}:hover & {
    background-image: url(${props => props.selectedSrc});
  }
`;

const LinkLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #EEF4F9;
  font-family: Montserrat;
`;

const Left = styled.div`
  width: ${leftWidth}px;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: white;
`;

const LeftContent = styled.div`
  height: calc(100vh - ${topHeight}px);
  max-height: calc(100vh - ${topHeight}px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 25px;
`;

const CurrentUserPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  //cursor: pointer;

  //:hover {
  //  opacity: 0.7;
  //}
`;

const CurrentUserAvatarPlaceholder = styled.div`
  height: 45px;
  width: 45px;
  background-color: lightgrey;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
  margin-right: 9px;
`;

const CurrentUserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
`;

const UserName = styled.div`
  color: #333333;
  font-weight: bold;
  cursor: pointer;
`;

const UserRating = styled.div`
  color: #939598;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Right = styled.div`
  height: 100vh;
  max-height: 100vh;
    // width: calc(100vw - ${leftWidth}px);
  overflow-y: auto;
  flex: 1;
`;

const HidingLinksMainPlaceholder = styled.div`
  display: ${props => (props.expanded == true ? 'block' : 'none')};
`;

const HidingLinksPlaceholder = styled.div`

`;

const LINKS = [
    {
        name: 'index',
        link: '/',
        label: 'Proxies',
        emojiIcon: emoji_cal,
        isTop: true
    },
    {
        name: 'checker',
        link: '/checker',
        label: 'Checker',
        emojiIcon: emoji_events,
        isTop: true
    },
    {
        name: 'settings',
        link: '/settings',
        label: 'Settings',
        emojiIcon: rating_img,
        isTop: true
    }

];
