import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function Tabs(props) {
    const [activeNumber, setActiveNumber] = useState(0);
    const {
        contentStyle = {},

        tabs = [{
            label: 'tab1',
            content: (
                <div>
                    tab 1
                </div>
            )
        },
            {
                label: 'tab2',
                content: (
                    <div>
                        tab 2
                    </div>
                )
            }]
    } = props;

    if (tabs.length == 0) {
        return null;
    }

    if (tabs.length == 1) {
        return tabs[0].content;
    }

    const activeContent = tabs[activeNumber].content;

    return (
        <Wrapper>
            <Heading>
                {tabs.map((tb, k) => {
                    let isSelected = (+k == +activeNumber);
                    let {isExtraLink} = tb;
                    return (
                        <HeadingItem selected={isSelected} key={k} onClick={() => {
                            setActiveNumber(k);
                        }}>
                            <TabName selected={isSelected}>
                                {tb.label}
                            </TabName>
                        </HeadingItem>
                    )
                })}

            </Heading>

            <ContentPlaceholder style={{...contentStyle}} >
                {activeContent}
            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const TabName = styled.div`
  margin-left: 7px;
  margin-right: 7px;
  border-bottom: ${props => (props.selected == true ? '4px solid #FFC02C' : '4px solid transparent')};
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 14px;

  :first-of-type {
    margin-left: 0px;
  }

  :hover {
    opacity: 0.8;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HeadingItem = styled.div`
  border-color: #dee2e6 #dee2e6 #fff;
  border-width: 1px;
  margin-right: 15px;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 21px;
  display: flex;
  flex-direction: row;

`;

// const TabName = styled.div`
//   color: ${props => (props.selected == false ? 'rgb(66, 86, 132)' : 'rgb(33, 33, 33)')};
//   border-bottom: ${props => (props.selected == true ? 'none' : '1px dotted rgb(66, 86, 132)')};
// `;

const ContentPlaceholder = styled.div`
  padding-top: 10px;
`;

const SupImagePlaceholder = styled.div`

`;

const SupLinkImg = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 4px;
  vertical-align: super;
`;

function openInNewTab(url) {
    let win = window.open(url, '_blank');
    win.focus();
}

export const TabItem = styled.div`
  padding-top: 0px;
`;
