import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CacheAPI from "../../../api/CacheAPI";

export default function VideoComp(props) {
    const {
        videoCode
    } = props;

    return (
        <Wrapper loop={1} autoPlay={true} muted={true}>
            <source src={CacheAPI.getVideoSrcByCode(videoCode)}/>
        </Wrapper>
    );
}

const Wrapper = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;