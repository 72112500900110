import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import vert_dots_img from '../../assets/img/more_vert_black_24dp.svg';


export default function DropdownDotsMenu(props) {
    const {
        items = [
            {
                name: 'Привет',
                onClick: () => {
                    window.alert('Привет');
                }
            },
            {
                name: 'Как дела?',
                onClick: () => {
                    window.alert('Как дела?');
                }
            }
        ]
    } = props;

    const [expanded, setExpanded] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setExpanded(false));
    if (items.length == 0) {
        return <IconPlaceholder></IconPlaceholder>;
    }

    return (
        <Wrapper>
            <IconPlaceholder expanded={expanded} onClick={() => {
                setExpanded(!expanded);
            }}>
                <Icon src={vert_dots_img}/>
            </IconPlaceholder>
            {expanded == false ? null :
                <MenuItemsPlaceholder ref={ref}>
                    {items.map((a, i) => {
                        return (
                            <MenuItem key={`zz_${i}_${a.id}`} onClick={() => {
                                try {
                                    a.onClick();
                                    setExpanded(false);
                                } catch (exc) {

                                }
                            }}>
                                {a.name}
                            </MenuItem>
                        )
                    })}
                </MenuItemsPlaceholder>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.7;
`;

const IconPlaceholder = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 1000px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${props => (props.expanded == true ? 'whitesmoke' : 'transparent')};

  :hover {
    background: whitesmoke;
  }
`;

const MenuItemsPlaceholder = styled.div`
  position: absolute;
  left: -180px;
  top: 32px;
  min-width: 180px;
  width: 180px;
  z-index: 10000;
  box-sizing: content-box;
  border: 1px solid #d3d3d3;

`;

const MenuItem = styled.div`
  width: 180px;
  box-sizing: border-box;

  padding-left: 20px;
  padding-right: 20px;
  height: 32px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;

  :hover {
    background: whitesmoke;
  }
`;

// Hook
export function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}