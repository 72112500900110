import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CacheAPI from "../../../api/CacheAPI";
import ReactHelper from "../../../helpers/ReactHelper";
import TodoViewCol from "../../todos/tools/TodoViewCol";

import x_logo from '../../../assets/img/x_logo.svg'
import logout_ic from '../../../assets/img/logout_ic.svg'
import * as usersActions from "../../../redux/actions/UsersActions";
import CommonHelper from "../../../helpers/CommonHelper";

export default function PigsMasterDashboardPanel(props) {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        CacheAPI.getAllTasks().then(arr => {
            setTasks(arr.filter(x => (x.status != 'finished')));
        });
    }, []);

    ReactHelper.useInterval(() => {
        CacheAPI.getAllTasks().then(arr => {
            setTasks(arr.filter(x => (x.status != 'finished')));
        });
    }, 3000);


    if (tasks.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }


    let xTasks = tasks.filter(x => (x.status == 'in_progress')).concat(tasks.filter(x => (x.status != 'in_progress')))

    // ReactHelper.useInterval(() => {
    //
    // }, 2000);

    return (
        <Wrapper>

            <TopPlaceholder>
                <LeftImg src={x_logo}/>
                <CenterPlaceholder>
                    Список задач
                </CenterPlaceholder>
                <RightImg src={logout_ic} onClick={async () => {
                    let pld = await dispatch(usersActions.logOut());
                    if (pld.error == undefined) {
                        CommonHelper.linkTo('/');
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    }
                }}/>
            </TopPlaceholder>

            <CenterPlaceholder>

                {xTasks.length > 0 ? null :
                    <div style={{padding: 10, textAlign: 'center'}}>
                        Нет задач для выполнения
                    </div>
                }

                <TodoViewCol
                    isPigsMaster={true}
                    items={xTasks}
                    onStatusChange={async (id, st) => {
                        await CacheAPI.changeTaskStatus(id, st);
                        let newTasks = (await CacheAPI.getAllTasks()).filter(x => (x.status != 'finished'));
                        setTasks(newTasks);
                    }}
                />
            </CenterPlaceholder>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
`;

const Left = styled.div`

`;

const LeftImg = styled.img`
  height: 50px;
`;

const RightImg = styled.img`
  height: 30px;
  cursor: pointer;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;


const Right = styled.div`

`;

const CenterPlaceholder = styled.div`
  padding: 5px;
`;

const Wrapper = styled.div`

  box-sizing: border-box;
  width: 100%;
  background: white;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
`;