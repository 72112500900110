import React, {
    useState,
    useEffect,
    useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";

const HOURS = Array.from({length: 24}).map((a, i) => +i);
const M_SLOTS = Array.from({length: 6}).map((a, i) => +i);

const RED = "rgba(255, 75, 86, 0.34)";
const YELLOW = "rgba(255, 220, 36, 0.5)";
const GREEN = "rgba(103, 205, 170, 0.65)";

export function perc2color(perc) {
    if (perc >= 50.0) {
        return GREEN;
    }
    if (perc > 0) {
        return YELLOW;
    }
    return RED;
}

function getSmoothPoints(arr) {
    let windowSize = 3;
    let resArr = JSON.parse(JSON.stringify(arr));
    for (let i = windowSize - 1; i < arr.length; i++) {
        let f = false;
        for (let j = i - windowSize + 1; j <= i; j++) {
            f = f || arr[j].s;
        }
        resArr[i].s = f;
    }
    return resArr;
}

export default function PhoneDayUptimePanel(props) {
    const {id, dayTimestamp} = props;
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(false);
    let pDur = 10 * 60 * 1000;

    useEffect(() => {
        let from = +moment(+dayTimestamp).startOf("day");
        let to = +moment(+dayTimestamp).endOf("day") + 1;
        setLoading(true);
        axios
            .get(`${API_ENDPOINT}/proxies/${id}/stats`, {
                params: {
                    from: from,
                    to: to
                }
            })
            .then((d) => d.data)
            .then((arr) => {
                setPoints(getSmoothPoints(arr));
                setLoading(false);
            });
    }, [id, dayTimestamp]);

    const slotsMap = useMemo(() => {
        let from = +moment(+dayTimestamp).startOf("day");
        let map = {};
        for (let i in points) {
            let p = points[i];
            let t = +p.t;
            let k = Math.floor((+t - +from) / pDur);
            let kT = +from + k * pDur;
            let key = `t-${kT}`;
            if (map[key] == undefined) {
                map[key] = {
                    t: kT,
                    value: 0,
                    number: 0,
                };
            }
            map[key].number = +map[key].number + 1;
            if (p.s == true) {
                map[key].value = +map[key].value + 1;
            }
        }
        for (let key in map) {
            if (map[key].number != undefined && map[key].number > 0) {
                map[key].percent = (100.0 * map[key].value) / map[key].number;
            }
        }
        return map;
    }, [loading, points, dayTimestamp, id]);

    if (loading == true) {
        return (
            <LoaderBlock>
                <div>Loading...</div>
            </LoaderBlock>
        );
    }

    return (
        <Wrapper>
            {HOURS.map((a, i) => {
                return (
                    <Hour key={`h-${i}`}>
                        {M_SLOTS.map((b, j) => {
                            let t =
                                +moment(dayTimestamp).startOf("day") +
                                3600 * i * 1000 +
                                pDur * j;
                            let slot = slotsMap[`t-${t}`];
                            let vPerc =
                                slot?.percent == undefined ? undefined : slot?.percent;
                            let col = vPerc == undefined ? "transparent" : perc2color(vPerc);
                            return (
                                <MinItem key={j} style={{backgroundColor: col}}>
                                    <TimeSpan>
                                        {`${`${i}`.padStart(2, "0")}:${`${j * 10}`.padStart(
                                            2,
                                            "0"
                                        )}`}
                                    </TimeSpan>
                                </MinItem>
                            );
                        })}
                    </Hour>
                );
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: stretch;
`;

const TimeSpan = styled.div`
  opacity: 0.5;
  font-size: 9px;
`;

const ValSpan = styled.div`
  opacity: 0.65;
  font-size: 7px;
  font-weight: bold;
`;

const Hour = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const MinItem = styled.div`
  flex: 1;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f5f587;
`;

const LoaderBlock = styled.div`
  background-color: whitesmoke;
  min-height: 300px;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
