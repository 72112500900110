import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import UserTemplate from "../templates/UserTemplate";
import ProxiesPanel from "../proxies/panels/ProxiesPanel";
import PigsTemplate from "../templates/PigsTemplate";
import TodoPanel from "../todos/dash/TodoPanel";

export default function TranslationsApp(props) {

    return (
        <PigsTemplate active={'translations'}>

            <Wrapper>


            </Wrapper>

        </PigsTemplate>
    );
}

const Wrapper = styled.div`
  padding: 10px;
  width: 1200px;
  margin: 0 auto;
`;