import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TodoPanel from "../todos/dash/TodoPanel";
import PigsMasterDashboardPanel from "../pigs_master/tools/PigsMasterDashboardPanel";

export default function PigsMasterApp(props) {

    return (
        <Wrapper>


            <PigsMasterDashboardPanel/>

            {/*<TodoPanel/>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  @media(max-width: 820px){
    width: 100vw;
  }
`;