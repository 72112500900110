import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

import video_aggression from '../assets/videos/agression.mp4'
import video_queue from '../assets/videos/eat.mp4'
import video_feeder_overload from '../assets/videos/drink.mp4'
import video_dead_pig from '../assets/videos/dead.mp4'

const DEFAULT_TASKS = [
    {
        number: 157,
        status: 'new',
        id: `tasks_new_1`,
        label: 'Неподвижное животное',
        timeLabel: '12 мин',
        locationLabel: '1 станок',
        staffLabel: 'Иванов Петр',
        topColor: '#2D9CDB',
        taskLabel: 'Подойти, проверить животных',
        videoCode: 'dead_pig'
    },
    {
        number: 15,
        status: 'new',
        id: `tasks_new_2`,
        label: 'Забилась кормушка',
        timeLabel: '58 мин',
        locationLabel: '3 станок',
        staffLabel: 'Симонов Николай',
        topColor: '#F2994A',
        taskLabel: 'Проверить на исправность кормушку',
        videoCode: 'feeder_overload'
    },
    {
        number: 24,
        status: 'new',
        id: `tasks_new_3`,
        label: 'Агресивное животное',
        timeLabel: '21 мин',
        locationLabel: '2 станок',
        staffLabel: 'Иванов Петр',
        topColor: '#EB5757',
        taskLabel: 'Проверить животное',
        videoCode: 'aggression'
    },
    {
        number: 37,
        status: 'new',
        id: `tasks_new_4`,
        label: 'Очередь у кормушки',
        timeLabel: '34 мин',
        locationLabel: '4 станок',
        staffLabel: 'Иванов Петр',
        topColor: '#F2994A',
        taskLabel: 'Проверить кормушку',
        videoCode: 'queue'
    }
]

const CacheAPI = {

    getVideoSrcByCode(code) {
        if (code == 'queue') {
            return video_queue;
        }
        if (code == 'dead_pig') {
            return video_dead_pig;
        }
        if (code == 'feeder_overload') {
            return video_feeder_overload;
        }
        return video_aggression;
    },

    async getValue(key) {
        let pld = (await axios.get(`${API_ENDPOINT}/cache/${key}`)).data;
        return pld;
    },

    async saveValue(key, value) {
        let pld = (await axios.post(`${API_ENDPOINT}/cache/${key}`, {
            value: value
        })).data;
        return pld;
    },

    async setDefaultTasks(key) {
        await this.saveValue('pigs_tasks', DEFAULT_TASKS);
    },

    async setEmptyTasks(key) {
        await this.saveValue('pigs_tasks', []);
    },



    async changeTaskStatus(taskId, newStatus) {
        let oldTasks = (await this.getValue('pigs_tasks')) || [];
        oldTasks = oldTasks.map((a, i) => (a.id == taskId ? {...a, status: newStatus} : {...a}));
        await this.saveValue('pigs_tasks', oldTasks);
    },

    async getTasksByStatus(status) {
        let oldTasks = (await this.getValue('pigs_tasks')) || [];
        return oldTasks.filter(xx => (xx.status == status));
    },

    async getAllTasks() {
        let oldTasks = (await this.getValue('pigs_tasks')) || [];
        return oldTasks;
    },


}

export default CacheAPI;
