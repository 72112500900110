import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import UserTemplate from "../templates/UserTemplate";
import ProxiesPanel from "../proxies/panels/ProxiesPanel";
import CheckerTool from "../proxies/tools/CheckerTool";
import Tabs, {TabItem} from "../ui/Tabs";
import TelegramInfoPanel from "../telegram/panels/TelegramInfoPanel";

export default function SettingsApp(props) {

    return (
        <UserTemplate active={'settings'}>

            <Wrapper>

                <Tabs
                    tabs={[
                        {
                            label: 'Notifications',
                            content: (
                                <TabItem>
                                    <Tabs
                                        tabs={[
                                            {
                                                label: 'Telegram',
                                                content: (
                                                    <TabItem>
                                                        <TelegramInfoPanel />
                                                    </TabItem>
                                                )
                                            },
                                            {
                                                label: 'Webhook',
                                                content: (
                                                    <TabItem>
                                                        Under construction
                                                    </TabItem>
                                                )
                                            }
                                        ]}
                                    />
                                </TabItem>
                            )
                        },
                        {
                            label: 'Profile',
                            content: (
                                <TabItem>

                                </TabItem>
                            )
                        }
                    ]}
                />

            </Wrapper>

        </UserTemplate>
    );
}

const Wrapper = styled.div`
  padding: 10px;
`;