import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import PhoneDayUptimePanel from "./PhoneDayUptimePanel";

export default function StatsTool(props) {
    const {
        id
    } = props;

    const [dayTimestamp, setDayTimestamp] = useState(+moment().startOf('day'));

    return (
        <Wrapper>

            <TopPlaceholder>

                <SideItem onClick={() => {
                    setDayTimestamp(+moment(dayTimestamp).add(-1, 'days'));
                }}>
                    {'<<<'}
                </SideItem>

                <CurrDate>
                    {moment(dayTimestamp).format('DD.MM.YYYY')}
                </CurrDate>

                <SideItem onClick={() => {
                    setDayTimestamp(+moment(dayTimestamp).add(1, 'days'));
                }}>
                    {'>>>'}
                </SideItem>

            </TopPlaceholder>

            <ContentPlaceholder>
                <PhoneDayUptimePanel
                    id={id}
                    dayTimestamp={dayTimestamp}
                />
            </ContentPlaceholder>

        </Wrapper>
    );
}

const SideItem = styled.div`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const CurrDate = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const ContentPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 300px;
`;