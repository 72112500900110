import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {useDispatch, useMappedState} from "redux-react-hook";

import Tabs, {TabItem} from "../../ui/Tabs";

import * as actions from '../../../redux/actions/ProxiesActions'
import {GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import ProxyForm from "../forms/ProxyForm";
import StatsTool from "../tools/StatsTool";
import ProxiesAPI from "../../../api/ProxiesAPI";

export default function ProxiesPanel(props) {
    const dispatch = useDispatch();

    const [stautusesMap, setStatusesMap] = useState({});
    const [checking, setChecking] = useState(false);

    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    let {currentUser, loading, proxies = []} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading || state.proxies.loading,
        proxies: state.proxies.proxiesMap.toArray()
    }), []));

    const selectedItem = (selectedId == undefined) ? undefined : proxies.filter(xx => (xx.id == selectedId))[0];

    useEffect(() => {
        dispatch(actions.loadProxies()).then(() => {
            setChecking(true);
            ProxiesAPI.getProxiesStatuses().then(arr => {
                let xMap = arr.reduce((mp, x) => ({...mp, [`status-${x.id}`]: x.status}), {});
                setStatusesMap(xMap);
                setChecking(false);
            })
        });
    }, []);

    if (proxies.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }


    return (
        <Wrapper>

            {checking == true ? 'загрузка...' : null}

            <TopPlaceholder>
                <GreenButton onClick={() => {
                    setAddVisible(true);
                }}>
                    Add proxy
                </GreenButton>
            </TopPlaceholder>

            <ListPlaceholder>
                {proxies.map((a, i) => {
                    let status = stautusesMap[`status-${a.id}`] || false;
                    return (
                        <ListItem
                            online={status}
                            selected={(a.id == selectedId)}
                            key={`${a.id}_${i}`} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <div>
                                {a.name}
                            </div>
                            <InfosPlaceholder>
                                {
                                    [
                                        {
                                            label: 'Type',
                                            value: 'type'
                                        },
                                        {
                                            label: 'Address',
                                            value: 'address'
                                        },
                                        {
                                            label: 'Port',
                                            value: 'port'
                                        },
                                        {
                                            label: 'Login',
                                            value: 'login'
                                        },
                                        {
                                            label: 'Password',
                                            value: 'password'
                                        },
                                    ].map((b, j) => {
                                        return (
                                            <InfoItem key={j}>
                                                <InfoLabel>
                                                    {b.label}
                                                </InfoLabel>
                                                <InfoValue>
                                                    {a[b.value]}
                                                </InfoValue>
                                            </InfoItem>
                                        )
                                    })
                                }
                            </InfosPlaceholder>
                        </ListItem>
                    )
                })}
            </ListPlaceholder>

            {addVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddVisible(false);
                }}>

                    <InnerPlaceholder>
                        <ProxyForm
                            loading={loading}
                            onChange={async d => {
                                await dispatch(actions.createProxy(d));
                                setAddVisible(false);
                            }}/>
                    </InnerPlaceholder>


                </NiceModal>
            }

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>

                        <div style={{textAlign: 'center'}}>
                            {`${selectedItem.type}://${selectedItem.login}:${selectedItem.password}@${selectedItem.address}:${selectedItem.port}`}
                        </div>

                        <Tabs
                            tabs={[
                                {
                                    label: 'Stats',
                                    content: (
                                        <TabItem>
                                            <StatsTool
                                                id={selectedId}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Update info',
                                    content: (
                                        <TabItem>
                                            <ProxyForm {...selectedItem}
                                                       loading={loading}
                                                       onChange={async d => {
                                                           await dispatch(actions.updateProxy(d));
                                                           setSelectedId(false);
                                                       }}/>
                                        </TabItem>
                                    )
                                },

                            ]}
                        />


                    </InnerPlaceholder>


                </NiceModal>
            }


        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  width: 820px;
  padding: 10px;
  box-sizing: border-box;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const InfoItem = styled.div`
  margin: 3px;
  border-radius: 4px;
  border: 1px dashed whitesmoke;
  padding: 5px;
`;

const InfoLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  opacity: 0.5;
`;


const InfoValue = styled.div`
  font-weight: bold;
  font-size: 14px;
`;


const InfosPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ListItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background: ${props => (props.selected == true ? 'blue' : (props.online ? '#90ee9036' : 'coral'))};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`

`;

const ListPlaceholder = styled.div`

`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
`;