import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ProxiesAPI = {

    async getLocations() {
        let pld = (await axios.get(`${API_ENDPOINT}/locations`)).data;
        return pld;
    },

    async getProxy(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/proxies/${id}`)).data;
        return pld;
    },

    async getAllProxies() {
        let arr = (await axios.get(`${API_ENDPOINT}/proxies`)).data;
        arr = arr.sort((a, b) => {
            return (+new Date(b.created_at) - +new Date(a.created_at))
        });
        return arr;
    },


    async createProxy(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/proxies`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld;
    },

    async updateProxy(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/proxies`, data)).data;
        return pld;
    },

    async deleteProxy(id) {
        await axios.delete(`${API_ENDPOINT}/proxies/${id}`, {
            data: {id: id}
        })
    },

    async getProxiesStatuses() {
        let pld = (await axios.get(`${API_ENDPOINT}/proxies-statuses`)).data;
        return pld;
    },

    async checkProxies(proxies) {
        let pld = (await axios.post(`${API_ENDPOINT}/tools/check_proxies`, {
            proxies: proxies
        })).data;
        return pld;
    },




}

export default ProxiesAPI;
