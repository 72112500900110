import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components'

const BasicButton = styled.div`
    font-family: Montserrat;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 1000px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    padding-left: 26px;
    padding-right: 26px;
    opacity: ${props => (props.disabled == true ? 0.3 : 1)};
`;

export const YellowButton = styled(BasicButton)`
    background: #FFC02C;
    color: #333333;
    :hover{
       background: ${props => (props.disabled == true ? '#FFC02C' : '#FFA71B')};
    }
`;

export const RedButton = styled(BasicButton)`
    background: #FF624F;
    :hover{
       background: ${props => (props.disabled == true ? '#FF624F' : '#CD402F')};
    }
`;

export const BlueButton = styled(BasicButton)`
    background: #085BFF;
    :hover{
       background: ${props => (props.disabled == true ? '#085BFF' : '#0C45B6')};
    }
`;

export const PurpleButton = styled(BasicButton)`
    background: #A36EFF;
    :hover{
       background: ${props => (props.disabled == true ? '#A36EFF' : '#8142EE')};
    }
`;

export const GreenButton = styled(BasicButton)`
    background: #15B559;
    :hover{
       background: ${props => (props.disabled == true ? '#15B559' : '#018137')};
    }
`;


