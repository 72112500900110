import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import white_cam_ic from '../../../assets/img/white_cam_ic.svg'

import clock_im from '../../../assets/img/clock_im.svg'
import home_im from '../../../assets/img/home_im.svg'
import man_im from '../../../assets/img/man_im.svg'
import CommonHelper from "../../../helpers/CommonHelper";


export default function ItemBox(props) {
    let {
        label,
        topColor,
        timeLabel,
        locationLabel,
        taskLabel,
        staffLabel,
        videoCode
    } = props;

    return (
        <Wrapper>
            <TopPlaceholder color={topColor}>
                <TopLeftSpan>
                    {label}
                </TopLeftSpan>
                <TopRightIcon src={white_cam_ic} onClick={() => {
                    CommonHelper.linkTo(`/video/${videoCode}`, true);
                }} />
            </TopPlaceholder>
            <ContentPlaceholder>
                <TaskPlaceholder>
                    {taskLabel}
                </TaskPlaceholder>
                <BottomPlaceholder>
                    <ItemSpan>
                        <ItemImg src={clock_im}/>
                        <ItSpan>
                            {timeLabel}
                        </ItSpan>
                    </ItemSpan>
                    <ItemSpan>
                        <ItemImg src={home_im}/>
                        <ItSpan>
                            {locationLabel}
                        </ItSpan>
                    </ItemSpan>
                    <ItemSpan>
                        <ItemImg src={man_im}/>
                        <ItSpan>
                            {staffLabel}
                        </ItSpan>
                    </ItemSpan>

                </BottomPlaceholder>
            </ContentPlaceholder>
        </Wrapper>
    );
}

const ItemImg = styled.img`
  height: 18px;
  margin-right: 5px;
`;

const ItSpan = styled.span`
    font-size: 14px;
  opacity: 0.8;
`;


const ItemSpan = styled.span`
  margin: 5px;
  display: inline-flex;
`;

const BottomPlaceholder = styled.div`

`;

const Wrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  box-sizing: border-box;
`;

const TaskPlaceholder = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const ContentPlaceholder = styled.div`
  padding: 18px;
`;

const TopRightIcon = styled.img`
  height: 32px;
  cursor: pointer;
  opacity: 0.7;
  :hover{
    opacity: 1;
  }
`;

const TopLeftSpan = styled.div`

`;

const TopPlaceholder = styled.div`
  padding: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: ${props => props.color};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;