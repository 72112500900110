import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Input} from "../../ira/ui/Inputs";
import {GreenButton} from "../../ira/ui/Buttons";
import LocationsSelector from "../tools/LocationsSelector";
import Spin from 'arui-feather/spin'
import Select from 'react-select'

const OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'Http',
        value: 'http'
    }
]

export default function ProxyForm(props) {
    const {
        loading = false,
        onChange = d => {

        }
    } = props;

    const [type, setType] = useState(props.type || 'socks5');
    const [address, setAddress] = useState(props.address || '');
    const [port, setPort] = useState(props.port || '');
    const [login, setLogin] = useState(props.login || '');
    const [password, setPassword] = useState(props.password || '');
    const [name, setName] = useState(props.name || '');
    const [pingers_locations, setPingersLocations] = useState(props.pingers_locations || []);

    return (
        <Wrapper>

            <Row>
                <Label>
                    Type
                </Label>
                <Select options={OPTIONS} value={OPTIONS.filter(x => (x.value == type))}
                        onChange={x => {
                            setType(x?.value);
                        }}
                />
            </Row>

            <Row>
                <Label>
                    Address
                </Label>
                <Input value={address} onChange={evt => {
                    setAddress(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                    Port
                </Label>
                <Input value={port} onChange={evt => {
                    setPort(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                    Login
                </Label>
                <Input value={login} onChange={evt => {
                    setLogin(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                    Password
                </Label>
                <Input value={password} onChange={evt => {
                    setPassword(evt.target.value);
                }}/>
            </Row>

            <Row>
                <Label>
                    Locations
                </Label>
                <LocationsSelector pingers_locations={pingers_locations} onChange={arr => {
                    setPingersLocations(arr);
                }}/>
            </Row>


            <BottomPlaceholder>
                <GreenButton onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onChange({
                        type,
                        address,
                        port: +port,
                        login,
                        password,
                        pingers_locations
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>Save</span>
                    }

                </GreenButton>
            </BottomPlaceholder>


        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;